/**
 * Activity Log
 **/
@keyframes cdetail-hilite {
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
}
#activityLog .table {
  table-layout: fixed;
}
#activityLog .table td {
  word-break: break-word;
}
#activityLog .table td.log-deteail {
  word-break: break-all;
}
#activityLog .table td.cdetail > div {
  overflow: hidden;
  height: 1.3em;
  border-bottom: 1px solid transparent;
  animation: 10000ms infinite cdetail-hilite;
  background: transparent;
  background: linear-gradient(90deg, transparent 45%, #ffff00 50%, transparent 55%);
  background-position: -1000px 0;
  background-size: 100%;
  background-repeat: no-repeat;
}
#activityLog .table .log-ts {
  width: 14em;
}
#activityLog .table .log-type {
  width: 7em;
}
#activityLog .table .log-desc {
  width: 15em;
}
#activityLog .table .log-table {
  width: 11em;
}
#activityLog .table .log-id {
  width: 8em;
}
#activityLog td.cdesc {
  max-width: 30vw;
}
