/**
 * When results are loading, hide pagination and show loader-wide
 **/
.result-paging {
  position: relative;
  min-height: 55px;
}
.result-paging > * {
  opacity: 1;
  z-index: 1;
  transition: opacity 500ms linear;
}
.result-paging > *.loader-wide {
  opacity: 0;
  z-index: -1;
}
.result-paging .loader-wide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.loading .result-paging > * {
  opacity: 0;
  z-index: 0;
}
.loading .result-paging > *.loader-wide {
  opacity: 1;
  z-index: 1;
}
