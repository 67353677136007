/**
 * Property Editor Lists
 **/
/**
 * Properties Field list - in modal Linked Field picker
 **/
.properties-fld-list {
  height: 75vh;
  overflow-y: auto;
}
.properties-fld-list ul {
  list-style: none;
  padding: 0;
}
.properties-fld-list ul > li {
  padding: 0 0 0 1.5em;
}
.properties-fld-list ul > li[data-fld]:hover {
  background: #d0d0d0;
}
.properties-fld-list .sub > span {
  font-style: italic;
}
.properties-fld-list .selected {
  background: lightblue;
  font-weight: bold;
}
/*
 The Grouped properties table (HS Properties -> Contact/Company/Deals)
 */
.table-properties {
  table-layout: fixed;
}
.table-properties th.field-mapping {
  width: 6em;
  border-left: 1px solid #777;
}
.table-properties th.field-mapping + .field-mapping {
  border-left: none;
}
.table-properties th.field-mapping.source-field {
  width: 15em;
}
.table-properties td.field-mapping {
  vertical-align: middle;
  padding: 0.25rem;
  background-color: #f8f8f8;
  border-left: 1px solid #ddd;
}
.hide-sync-choices .mdd_sync_choice {
  display: none;
}
.mdd_sync_choice {
  position: relative;
}
.mdd_sync_choice label,
.mdd_sync_choice input {
  display: none !important;
}
.mdd_sync_choice.show-input label,
.mdd_sync_choice.show-input input {
  display: inline-block !important;
}
.mdd_sync_choice.show-input div {
  display: none;
}
.field-mapping div.flags-choices {
  display: none;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translate(-100%, -50%);
  z-index: 100;
  padding: 0.5em;
  background-color: #d0d0d0;
  color: #000;
  border: 1px solid #000;
  white-space: nowrap;
}
.field-mapping div.flags-choices label {
  display: block !important;
}
.field-mapping div.flags-choices label > input {
  margin-right: 0.4em;
}
.field-mapping.sync-choice-selected:hover .show-input div.flags-choices,
.field-mapping.sync-choice-selected:hover .show-input div.flags-choices:hover {
  display: block !important;
}
.field-mapping.flags-set {
  position: relative;
}
.field-mapping.flags-set:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: #0091ff #0091ff transparent transparent;
  border-style: solid;
  border-width: 8px;
}
.more-info {
  position: relative;
  cursor: pointer;
}
.more-info > div {
  position: absolute;
  top: 50%;
  left: 0%;
  padding: 0.5rem;
  width: 18em;
  transform: translate(-100%, -50%) scale(0.01, 0.01);
  transform-origin: 50% 50%;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  background-color: #f8f8f8;
  border: 1px solid #aaa;
  box-shadow: 0 0 15px 1px #77777780;
  opacity: 0;
  transition: all 300ms ease-in-out;
}
.more-info > div > *:first-child {
  margin-top: 0;
}
.more-info > div > *:last-child {
  margin-bottom: 0;
}
.more-info:hover > div {
  opacity: 1;
  transform: translate(-100%, -50%) scale(1, 1);
}
.more-info:hover > div:after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  width: 1px;
  height: 1px;
  border-width: 0.75em;
  border-style: solid;
  border-color: transparent transparent transparent #aaa;
}
.more-info:before {
  display: block;
  content: 'More Info';
  position: absolute;
  top: -1.2em;
  left: 0;
  font-size: 8px;
}
.modal-body li:nth-child(odd) {
  background-color: #f8f8f8;
}
