/**
 * CSS For App
 **/


body {
    font-family: 'Source Sans 3', sans-serif;
}


/**
 * Prevent modal titles from being pushy
 **/
.modal-title {
    overflow: hidden;
}


/**
 * Headings
 **/

h2, .h2 {
    font-size: 1.3rem;
}

h3, .h3 {
    font-size: 1.2rem;
}

h4, .h4 {
    font-size: 1.1rem;
}

h5, .h5 {
    font-size: 1.0rem;
}

h6, .h6 {
    font-size: 1.1rem;
}

/**
 * Heading - solid bkgd, centered, no margins
 **/
.db-heading {
    padding: 0.5em;
    margin: 1px;
    font-size: 1.15em;
    text-align: center;
    background-color: #184483;
    color: white;
    border: 1px solid #00000020;
    position: relative;

    .left-item,
    .right-item {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        display: flex;
        align-items: center;
    }
    .left-item {
        left: 0.5em;
        text-align: left;
    }
    .right-item {
        right: 0.5em;
        text-align: right;
    }
    .server-time {
        text-align: left;
    }
    .server-auto-run {
        & > button {
            display: none;
        }
        &.is-running > .is-running,
        &.is-stopped > .is-stopped {
            display: inline-block;
        }
    }

}




/**
 * Cards
 **/
.card-title {
    padding: 0.4rem;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.0rem;
    text-align: center;
    background-color: #184483;
    color: white;
}
.card-body {
    padding: 0;
}


/**
 * Navigation
 **/



// Align icons (center) and text (left spaced from icon)
.align-icons-center() {
    // Match font-awesome icons.
    i {
        width: 1.3em;
        text-align: center;
        margin-right: 0.15em;
    }
}


// right aligned nav menus
.ml-auto .dropdown-menu {
    .align-icons-center;
}

@media (min-width: 576px) {
    .navbar-nav .nav-link {
        white-space: nowrap;
    }
}
// Mobile menu active
@media (max-width: 991px) {
    .nav-item {
        .align-icons-center;
    }

    .navbar-nav .dropdown-menu {
        background-color: transparent;
        border: none;
        padding: 0 0 0 2em;
    }
}

// Navigation item is selected
.nav-item.active > a > i {
    color: #5AA144;
}


// CIL Icons slightly high
i[class^="cil-"],i[class^="cib-"],i[class^="cif-"]{
    position: relative;
    top: 0.2em;
}