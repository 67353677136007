/**
 * Property Editor Lists
 **/



/**
 * Properties Field list - in modal Linked Field picker
 **/
.properties-fld-list {
    height: 75vh;
    overflow-y: auto;

    ul {
        list-style: none;
        padding: 0;

        & > li {
            padding: 0 0 0 1.5em;

            // For data-fld items add hover
            &[data-fld]:hover {
                background: #d0d0d0;
            }
        }
    }

    .sub>span {
        font-style: italic;
    }

    .selected {
        background: lightblue;
        font-weight: bold;
    }
}


/*
 The Grouped properties table (HS Properties -> Contact/Company/Deals)
 */
.table-properties {
    table-layout: fixed;

    th.field-mapping {
        width: 6em;
        border-left: 1px solid #777;
        & + .field-mapping {
            border-left: none;
        }
    }
    th.field-mapping.source-field {
        width: 15em;
    }
    td.field-mapping {
        vertical-align: middle;
        padding: 0.25rem;
        background-color: #f8f8f8;
        border-left: 1px solid #ddd;
    }
}

.hide-sync-choices {
    .mdd_sync_choice {
        display: none;
    }
}
.mdd_sync_choice {
    position: relative;

    label, input {
        display: none !important;
    }
    &.show-input {
        label, input {
            display: inline-block !important;
        }
        div {
            display: none;
        }
    }
}

.field-mapping {
    div.flags-choices {
        display: none;
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translate(-100%, -50%);
        z-index: 100;
        padding: 0.5em;
        background-color: #d0d0d0;
        color: #000;
        border: 1px solid #000;
        white-space: nowrap;

        label {
            display: block !important;
            & > input {
                margin-right: 0.4em;
            }
        }
    }
    // Show only when hovering and its tagged to show
    &.sync-choice-selected:hover .show-input {
        div.flags-choices {
            &, &:hover {
                display: block !important;
            }
        }
    }

    &.flags-set {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            border-color: rgb(0, 145, 255) rgb(0, 145, 255) transparent transparent;
            border-style: solid;
            border-width: 8px;
        }
    }
}

// More info is alternate content for sync controls that display when control is not to.
.more-info {
    position: relative;
    cursor: pointer;

    & > div {
        position: absolute;
        top: 50%;
        left: 0%;
        padding: 0.5rem;
        width: 18em;
        transform: translate(-100%, -50%) scale(0.01, 0.01);
        transform-origin: 50% 50%;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        text-align: left;
        background-color: #f8f8f8;
        border: 1px solid #aaa;
        box-shadow: 0 0 15px 1px #77777780;
        opacity: 0.0;
        transition: all 300ms ease-in-out;

        & > *:first-child {
            margin-top: 0;
        }
        & > *:last-child {
            margin-bottom: 0;
        }
    }
    &:hover {
        & > div {
            opacity: 1.0;
            transform: translate(-100%, -50%) scale(1.0, 1.0);

            &:after {
                display: block;
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);
                width: 1px;
                height: 1px;
                border-width: 0.75em;
                border-style: solid;
                border-color: transparent transparent transparent #aaa;
            }
        }
    }
    &:before {
        display: block;
        content: 'More Info';
        position: absolute;
        top: -1.2em;
        left: 0;
        font-size: 8px;
    }

}

.modal-body li {

    &:nth-child(odd) {
        background-color: #f8f8f8;
    }
}