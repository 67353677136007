/**
 * Downloads page
 **/


.export-files {
    .tools {
        width: 5rem;
    }
    .size {
        width: 8rem;
        text-align: right;
    }
    th.size {
        padding-right: 2.5rem;
    }
    .mtime {
        width: 12rem;
        text-align: center;
    }
}
