/**
 * Activity Log
 **/



@keyframes cdetail-hilite {
    from { background-position: -1000px 0; }
    to { background-position: 1000px 0; }
}

#activityLog {
    .table {
        table-layout: fixed;

        td {
            word-break: break-word;
            &.log-deteail {
                word-break: break-all;
            }
            &.cdetail > div {
                overflow: hidden;
                height: 1.3em;
                border-bottom: 1px solid transparent;
                animation: 10000ms infinite cdetail-hilite;
                background: transparent;
                background: linear-gradient(90deg, transparent 45%, rgb(255,255,0,0.2) 50%, transparent 55%);
                background-position: -1000px 0;
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }

        .log-ts { width: 14em; }
        .log-type { width: 7em; }
        .log-desc { width: 15em; }
        .log-table { width: 11em; }
        .log-id { width: 8em; }
    }

    td.cdesc {
        max-width: 30vw;
    }
}