/**
 * General Forms and buttons
 **/
input:not(.custom-control-input):not(.bs),
select,
textarea {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  line-height: 1rem;
  min-height: 1.5rem;
  color: #283164;
  background: white;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px #555555;
  transition: background-color 300ms linear, box-shadow 200ms linear, border-color 200ms linear;
}
input:not(.custom-control-input):not(.bs):hover,
select:hover,
textarea:hover,
input:not(.custom-control-input):not(.bs):focus,
select:focus,
textarea:focus {
  border-color: #333333;
  box-shadow: 0 0 1px #333333;
}
input[type="checkbox"]:not(.custom-control-input):not(.bs),
input[type="radio"]:not(.custom-control-input):not(.bs) {
  display: inline-block;
  position: relative;
  appearance: none;
  padding: 0;
  margin-top: 0.55rem;
  height: 0.7rem;
  width: 0.7rem;
  min-height: auto;
  outline: 0;
  border: 0;
  background-color: #efefef;
  box-shadow: none;
  vertical-align: top;
  cursor: pointer;
}
input[type="checkbox"]:not(.custom-control-input):not(.bs):after,
input[type="radio"]:not(.custom-control-input):not(.bs):after {
  content: '';
  display: block;
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  left: -0.2rem;
  border: 1px solid #55555588;
  box-shadow: 0 0 1px #555555;
  transition: background-color 300ms linear, box-shadow 200ms linear;
}
input[type="checkbox"]:not(.custom-control-input):not(.bs):hover:after,
input[type="radio"]:not(.custom-control-input):not(.bs):hover:after,
input[type="checkbox"]:not(.custom-control-input):not(.bs):focus:after,
input[type="radio"]:not(.custom-control-input):not(.bs):focus:after {
  border-color: #333333;
  box-shadow: 0 0 2px #333333;
}
input[type="checkbox"]:not(.custom-control-input):not(.bs):checked,
input[type="radio"]:not(.custom-control-input):not(.bs):checked {
  background-color: #5aa4ff;
}
input[type="checkbox"]:not(.custom-control-input):not(.bs) {
  border-radius: 0.1rem;
}
input[type="checkbox"]:not(.custom-control-input):not(.bs):after {
  border-radius: 0.1rem;
}
input[type="radio"]:not(.custom-control-input):not(.bs) {
  border-radius: 50%;
}
input[type="radio"]:not(.custom-control-input):not(.bs):after {
  border-radius: 50%;
}
input[type="range"] {
  margin: 0;
  padding: 0;
}
input[type="range"],
input[type="range"]::-webkit-slider-thumb {
  appearance: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  box-sizing: border-box;
  background-color: #184483;
  height: 0.8em;
  border: none;
  border-radius: 0.4em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
input[type="range"]::-webkit-slider-thumb {
  margin-top: -0.3em;
  box-sizing: border-box;
  background-color: #fff;
  height: 1.5em;
  width: 1.5em;
  border: 1px solid #184483;
  border-radius: 50%;
  outline: none;
}
input[type="range"]::-moz-range-track {
  box-sizing: border-box;
  background-color: #184483;
  height: 0.8em;
  border: none;
  border-radius: 0.4em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
input[type="range"]::-moz-range-thumb {
  box-sizing: border-box;
  background-color: #fff;
  height: 1.5em;
  width: 1.5em;
  border: 1px solid #184483;
  border-radius: 50%;
  outline: none;
}
input[type="range"]::-ms-track {
  box-sizing: border-box;
  background-color: #184483;
  height: 0.8em;
  border: none;
  border-radius: 0.4em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
input[type="range"]::-ms-thumb {
  box-sizing: border-box;
  background-color: #fff;
  height: 1.5em;
  width: 1.5em;
  border: 1px solid #184483;
  border-radius: 50%;
  outline: none;
}
.help {
  font-size: 0.8em;
  color: #888;
}
.help a {
  color: #555;
  text-decoration: underline;
}
.help a:focus,
.help a:hover {
  text-decoration: none;
}
.option-help {
  position: relative;
  top: -0.1em;
  display: inline-block;
  padding: 0;
  margin: 0 0 0 0.25em;
  width: 1.5em;
  height: 1.5em;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 3px #00000088;
  background-color: white;
  color: darkgreen;
  font-weight: bold;
  font-size: 0.8rem;
  text-decoration: none;
  transition: all 250ms linear;
}
.option-help:focus,
.option-help:hover {
  text-decoration: none;
  color: white;
  background-color: darkgreen;
  box-shadow: 0 0 5px #00880088;
}
.option-help-content {
  padding: 0.25em 0.5em;
  margin-top: 0.5em;
  box-shadow: 0 0 2px #00880088;
  border-radius: 0.25em;
  font-size: 0.8em;
  color: #333;
  background-color: #e9f8e888;
}
select {
  appearance: none;
  padding-right: 1.5rem !important;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
.auto-run {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.auto-run > * {
  margin-right: 0.5em;
}
.auto-run > *:last-child {
  margin-right: 0;
}
.auto-run select {
  width: auto;
}
.auto-run .repeatval {
  width: 6em;
}
.form-group-values {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-body form {
  padding: 0 1em;
}
@media (min-width: 576px) {
  .col-sm-1.col-form-label,
  .col-sm-2.col-form-label,
  .col-sm-3.col-form-label,
  .col-sm-4.col-form-label,
  .col-sm-5.col-form-label,
  .col-sm-6.col-form-label,
  .col-sm-7.col-form-label,
  .col-sm-8.col-form-label {
    text-align: right;
  }
}
