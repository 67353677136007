/**
 * Downloads page
 **/
.export-files .tools {
  width: 5rem;
}
.export-files .size {
  width: 8rem;
  text-align: right;
}
.export-files th.size {
  padding-right: 2.5rem;
}
.export-files .mtime {
  width: 12rem;
  text-align: center;
}
