/**
 Status Items
 **/

@keyframes showChanged {
    0% { background-color: #f1f0a5ff; }
    100% { background-color: #ffffff00; }
}

/* For queue item details, no border and indent */
.queue-action-area {
    tr.queueItemDetail > td {
        border-top: 0;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

// @media (min-width: 500px) {
//     .queue-action-area {
//         & > .db-heading {
//         }
//     }
// }

.queue-status {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    margin-bottom: 1rem;

    & > div {
        flex: 1 0 auto;
    }

    .card {
        flex: 1 1 auto;
        margin-bottom: 0.4rem;
    }

    .card-body {
        padding: 0;
    }

    h5 {
        margin: 0;
        line-height: 1rem;
    }
    h5 {
        padding: 0.4em 0;
        font-size: 1.0rem;
        text-align: center;
        background: #f0f0f0;
    }
    dl + h5 {
        margin-top: 0.4rem;
    }
}

.server-time {
    font-size: 0.5rem;
    display: inline-block;
    width: 3rem;
    margin-right: 0.5rem;
}

.server-auto-run {
    button {
        span {
            display: none;
        }
    }
}
@media (min-width: 400px) {
    .server-time {
        width: 6rem;
        margin-right: 0;
    }
}

@media (min-width: 900px) {
    .server-time {
        font-size: 0.65rem;
        width: 8rem;
    }

    .server-auto-run {
        button {
            span {
                display: inline-block;
            }
        }
    }
}




.status-item-tables {
    display: flex;
    flex-wrap: wrap;
    & > div {
        flex: 1 1 14rem;
    }
}

.status-item-groups {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
}
.status-item-group {
    position: relative;
    flex: 1 1 4.5rem;
    margin: 0;

    // The data - this is .status-item
    & > span {
        display: block;
        font-size: 0.9rem;
        padding: 0.5em 0 0.2em 0;
        text-align: center;
        transition: all 300ms ease-in-out;
    }
    // The label
    & > .status-item-label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        color: #777;
        font-size: 0.6rem;
        padding: 0 0 0 0.4em;
    }
}
.status-item {
    transition: all 300ms ease-in-out;

    &.changed {
        background-color: #f1f0a5;
        color: #0b0f50;
        animation: showChanged 4s;
        animation-fill-mode: forwards;
    }
}

/* Control to toggle between short and detail layouts */
.queue-layout-toggle {
    .detail {
        display: none;
    }
    /* When toggled in 'short' mode */
    .queue-layout-short & {
        /* Change the toggle's label */
        .detail {
            display: inline;
        }
        .short {
            display: none;
        }
    }
}
.queue-layout-short {
    /* Nix labels and totals */
    .status-item-group.group-size,
    .status-item-group > .status-item-label {
        display: none;
    }
    /* Special handle last update */
    .status-item-group.group-last {
        display: block;
        position: absolute;
        top: 0.45rem;
        right: 0.5rem;
        left: 0.5rem;;
        line-height: 1;
        font-size: 0.7rem;
        text-align: right;
        & > span {
            position: static;
            display: inline;
            font-size: inherit;
            padding: 0;
            margin: 0 0 0 0.25em;
            &.status-item-label {
                display: none;
            }
        }
    }

    /* The basis of the individual tables can be smaller */
    .status-item-table {
        flex: 1 1 7rem;
        position: relative;
        & > h5 {
            position: absolute;
            top: 0;
            left: 0.75em;
            font-size: 0.7rem;
            background-color: transparent;
        }
        /* Make ToDo bigger */
        .status-item {
            padding: 0.5rem 0 0 0;
            font-size: 2.0rem;
        }

        & + .status-item-table {
            border-top: 1px solid #e0e0e0;
        }
    }
}

/* If toggle for 'short' set, place status to left are actions */
@media (min-width: 768px) {
    .queue-layout-short {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        align-content: stretch;

        & > .queue-status-area {
            flex: 0 0 12rem;
            margin-right: 1rem;
        }
        & > .queue-action-area {
            flex: 1 1 auto;
        }
    }
}
