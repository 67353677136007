

.error-log {
    p {
        padding-left: 5em;
        text-indent: -5em;
        line-height: 1.0em;
    }
    span {
        font-size: 0.8em;
    }
}