.hidden-value {
  height: 0;
  position: absolute;
  top: -1000em;
}
.queueItem button {
  margin-top: -4px;
  margin-bottom: -4px;
}
/*
 Container with name of command and arguments
 <div class="cmd-display">
   <strong>Command</strong>
   <span class="cmd-args">
     <dl>
       <dt>Arg Label</dt>
       <dd>Arg Value</dd>
     </dt>
     ...
   </span>
 </div>
*/
.cmd-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  line-height: 1.4;
}
.cmd-display > strong {
  display: inline-block;
  margin-right: 0.5em;
}
.cmd-arguments {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 0.8em;
  margin: -0.15em 0;
}
.cmd-arguments dl {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  line-height: 1.5;
  border-radius: 0.5em;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.075);
  margin: 0.15em 0.15em 0.15em 0;
}
.cmd-arguments dl:last-child {
  margin-right: 0;
}
.cmd-arguments dt {
  flex: 0 0 auto;
  border-radius: 0.5em 0 0 0.5em;
  background: white;
  color: #aaa;
  padding: 0 0.25em 0 0.5em;
  margin: 0;
}
.cmd-arguments dd {
  flex: 0 1 auto;
  padding: 0 0.5em;
  margin: 0;
  word-break: break-all;
}
.cmd-arguments .value-error {
  color: #ff3333;
  border-color: #ff3333;
}
.cmd-arguments .value-default {
  color: #aaaaaa;
}
.cmd-arguments :not(.value-default) dd {
  color: #00701c;
}
.value-errors {
  margin-top: 0.25em;
  font-size: 0.8em;
  color: #ff3333;
}
.value-errors p {
  margin: 0;
}
.value-errors p + p {
  margin-top: 0.25em;
}
/* Separate arguments in todo queue */
.cmd-args {
  font-size: 0.8em;
  position: relative;
  top: -0.1em;
}
.cmd-args span {
  display: inline-block;
  padding: 0 0.3em;
  border-radius: 0.5em;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.075);
}
.cmd-args span + span {
  margin-left: 0.15em;
}
.cmd-args dt {
  flex: 0 0 auto;
  border-radius: 0.5em 0 0 0.5em;
  background: white;
  color: #aaa;
  padding: 0 0.25em 0 0.5em;
  margin: 0;
}
.cmd-args dd {
  flex: 0 1 auto;
  padding: 0 0.5em;
  margin: 0;
}
/*
Queue Item groups. At least 2 groups: Options, Scheduling
<fieldset class="queue-item-group">
    <legend>Group Name</legend>
    <div class="description">Optional description</div>
    <div class="form-group row ...">...</div>
    ...
</fieldset>
*/
.queue-item-group {
  /* Description */
  /* Help labels */
}
.queue-item-group legend {
  display: block;
  padding: 0.25rem 0.5rem;
  margin: 0 0 1rem 0;
  background-color: #447fd8;
  color: white;
  font-size: 1rem;
  font-weight: 100;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.5em;
}
.queue-item-group .description {
  margin: -1rem 0 1rem 0;
  padding: 0.25rem 0.5rem;
  background-color: #f0f0f0;
  border-color: #ddd;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  font-size: 0.85em;
}
.queue-item-group .btn-link {
  display: inline;
  font-size: inherit;
  line-height: inherit;
  padding: unset;
}
.queue-item-group .help3 {
  position: relative;
  margin-top: 0.25em;
  line-height: 1.2em;
  min-height: 1.2em;
}
.queue-item-group .help3 button.single-line-toggle {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  color: inherit;
  line-height: inherit;
  font-size: inherit;
  text-align: left;
  overflow: hidden;
  max-height: 1.9em;
  outline: none;
  opacity: 1;
  transition: opacity 150ms linear;
}
.queue-item-group .help3 button.single-line-toggle[aria-expanded="true"] {
  opacity: 0;
}
.queue-item-group .help3 button.single-line-toggle:after {
  display: block;
  content: '-- more --';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
  line-height: inherit;
  height: 1em;
  padding-top: 0.8em;
  background: #ffffff;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.queue-items-filter label {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 0;
}
.queue-items-filter label input {
  margin-left: 0.5rem;
}
.queue-items-filter input.updating {
  background: radial-gradient(#447fd888, #fff0, #fff0);
  background-size: 10% 400%;
  animation: gradient 3000ms linear infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.table-itemdetail {
  font-size: 0.95em;
}
.table-itemdetail th {
  text-align: left;
  font-weight: normal;
  padding: 0.5em 0.5rem 0.5em 0.5rem;
}
.table-itemdetail td {
  position: relative;
  text-align: center;
  width: 5rem;
  padding: 0.5em 0.25rem 0.5em 0.25rem;
  border-left: 1px solid #aaa;
}
.table-itemdetail td.stat-processed {
  font-weight: bold;
}
.table-itemdetail tr:first-child td {
  padding-top: 0.75em;
  padding-bottom: 0.25em;
}
.table-itemdetail tr:first-child td:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #777;
  font-size: 0.6rem;
  font-weight: normal;
  padding: 0 0 0 0.4em;
  text-align: center;
}
.table-itemdetail tr:first-child td.stat-processed:before {
  content: 'Processed';
}
.table-itemdetail tr:first-child td.stat-inserts:before {
  content: 'Inserts';
}
.table-itemdetail tr:first-child td.stat-updates:before {
  content: 'Updates';
}
.table-itemdetail tr:first-child td.stat-deletes:before {
  content: 'Deletes';
}
.table-itemdetail tr:first-child td.stat-errors:before {
  content: 'Errors';
}
.item-errors {
  word-break: break-word;
  overflow: auto;
}
.qPagination {
  margin: 0.5rem 0;
}
.qPagination div {
  text-align: center;
}
.qPagination ul,
.qPagination li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.qPagination ul {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}
.qPagination li {
  margin: 0 1rem;
}
.qPagination a {
  display: block;
  padding: 0.2rem 0.6rem;
  border: none;
  box-shadow: 0 0 0.3rem 0 #77777788;
  line-height: 1em;
  font-size: 0.8rem;
  text-decoration: none;
  color: #777;
  background: #f8f8f8;
  transition: all 300ms ease-in-out;
}
.qPagination a:focus,
.qPagination a:hover {
  text-decoration: none;
  background: #447fd823;
}
