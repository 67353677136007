/**
* CSS for Contact Create viewer
**/
.contact-create-list-view {
  position: relative;
}
.contact-create-list-view .loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}
.contact-create-list-view > form,
.contact-create-list-view > .results {
  transition: opacity 500ms linear;
  opacity: 1;
}
.contact-create-list-view.is-loading .loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-create-list-view.is-loading > form,
.contact-create-list-view.is-loading > .results {
  opacity: 0.3;
}
.contact-create-list-view .disabled-request td {
  background-color: rgba(250, 180, 180, 0.3);
}
.contact-create-list-view .c-date {
  width: 15em;
}
.contact-create-list-view .request-error-msg {
  display: block;
  overflow-y: hidden;
  max-height: 1.5em;
}
.obj-list label {
  margin: 0;
}
.obj-list .row + .row {
  margin-top: 0.5rem;
}
.obj-list .obj-list {
  border-style: solid;
  border-color: rgba(127, 127, 127, 0.3);
  border-width: 1px 0;
}
ul.alert-contents {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.alert-contents > li {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.alert-contents > li + li {
  margin-top: 0.5em;
  padding-top: 0.5em;
  border-top: 1px solid #cccccc;
}
ul.alert-contents > li h3 {
  margin: 0 0 0.15rem 0;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
}
